<template>
  <v-dialog v-model="approvalDialog" :key="item._id" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="grey lighten-1">
        <font-awesome-icon
          v-if="item.isDisable"
          :icon="['fas', 'check-circle']"
          color="success"
        />
        <font-awesome-icon v-else :icon="['fas', 'ban']" color="danger" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h3 class="title text-start text-break">
          Are you sure you want to
          {{ item.isDisable ? "disable " : "enable the admin" }}
          <span class="text-capitalize">'{{ item.name }}'</span>?
        </h3>
      </v-card-title>
      <v-card-text>
        <p class="subtitle-2 text-left">
          <span class="text-capitalize">'{{ item.name }}'</span>
          will become {{ item.isDisable ? "disabled" : "enabled" }} on the
          Conferli Admin.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="approvalDialog = false"
          >No, Cancel</v-btn
        >
        <v-btn
          color="error"
          text
          v-if="item.isDisable"
          @click="
            approvalFn(item);
            approvalDialog = false;
          "
          >Yes, Disable
        </v-btn>
        <v-btn
          color="success"
          text
          v-else
          @click="
            approvalFn(item);
            approvalDialog = false;
          "
          >Yes, Enable</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DisableConfirmation",
  props: {
    item: Object,
    approvalFn: Function
  },
  data() {
    return {
      approvalDialog: false
    };
  },
  computed: {},
  watch: {}
};
</script>
